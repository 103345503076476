

import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Home } from './components/Home';
import { Cookies } from './components/Cookies';
import { Condizioni } from './components/Condizioni';
import { Privacy } from './components/Privacy';
import { Footer } from './components/footer';
import { NotFound } from './components/NotFound';

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <div>
          <Routes>
            <Route path='cookies' element={<Cookies />} />
            <Route path='condizioni' element={<Condizioni />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='/' element={<Home />} />
            <Route path='*' element={<NotFound />}/>
          </Routes>
        </div>
      <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
