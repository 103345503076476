import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';

export const NotFound = () => {

    document.title = '404 Page Not found';

    return (
        <div>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <br></br><br></br><br></br>
            <h1>Oops! Pagina non trovata.</h1>
            <p>Premi per tormare alla pagina principale:</p>
            <br></br>
            <Button variant="warning"
                href="/">Torna alla pagina principale:
            </Button>


            <br></br><br></br><br></br>
        </div>
    )
}
export default NotFound;