
import logo from './images/logo.png';

import {
    Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { Link as Links } from 'react-scroll';
import NavDropdown from 'react-bootstrap/NavDropdown';


import Phplist from './phplist';
import Contatti from './contatti';

export const Navbar1 = () => {

    return (
        <>
            <Navbar bg="light" expand="lg" fixed='top'>
                <Container>

                    <Phplist />
                    <Contatti />

                    <img src={logo} alt="Compriamo farmacie" width="30" height="30" style={{margin: 15 }} className="d-inline-block align-text-top"></img>
                    <Navbar.Brand href="/" title="Noi Compriamo Farmacie"> Noicompriamofarmacie</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="navbar-brand" href="#">

                            <Nav.Link as={Link} to="/">Home</Nav.Link>

                            {/* impostsre uno stato e testare per rendere visibile il sottomenu */}
                            <NavDropdown as={Link} to="/">Home
                                <NavDropdown.Item as={Links} to="Noicompriamofarmacie" spy={true} smooth={true} offset={-100} duration={500}>Noi compriamo farmacie</NavDropdown.Item>
                                <NavDropdown.Item as={Links} to="Compriamo la tua farmacia" spy={true} smooth={true} offset={-160} duration={500}>Compriamo la tua farmacia</NavDropdown.Item>
                                <NavDropdown.Item as={Links} to="Chi siamo" spy={true} smooth={true} offset={-50} duration={500}>Chi siamo</NavDropdown.Item>
                                <NavDropdown.Item as={Links} to="Cosa facciamo" spy={true} smooth={true} offset={-60} duration={500}>Cosa facciamo</NavDropdown.Item>
                            </NavDropdown>
                            {/* impostsre uno stato e testare per rendere visibile il sottomenu */}

                            <NavDropdown title="Privacy & Cookie" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/condizioni">Condizioni d’uso e Termini del servizio</NavDropdown.Item>
                                <NavDropdown.Item href="/privacy">Trattamento dei dati personali (Privacy)</NavDropdown.Item>
                                <NavDropdown.Item href="/cookies">Informazioni Generali sull’Uso dei Cookie</NavDropdown.Item>
                            </NavDropdown>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navbar1;