import React from "react";
import { Navbar2 } from './navbar2';

export const Privacy = () => {

    document.title = 'Gestione Privacy';

    return (
        <>
            <Navbar2 />
            <div style={{ marginTop: '100px' }}>

                <div className="container-fluid pt-5 g-0" style={{
                    width: "80%",
                    marginTopTop: 80,
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    paddingTop: 60,
                    paddingBottom: 30,
                    border: "3px solid lightGray",
                }}>
                    <h1>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI</h1>
                </div>

                <div className="container-fluid pt-3 g-0" style={{
                    width: "80%",
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    paddingTop: 30,
                    paddingBottom: 30,
                    border: "3px solid lightGray",
                }}>


                    <h4>INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI AI SENSI DEL REGOLAMENTO UE 2016/679</h4>
                    Le seguenti informazioni ti consentiranno di prestare consenso informato libero e consapevole al trattamento dei tuoi dati personali che ci hai fornito spontaneamente per ottenere informazioni e servizi che la nostra società offre. In base al Regolamento in vigore (UE 2016/679) ti informiamo che :
                    <ul>
                        <li style={{ listStyleType: 'none' }}>
                            <ul>
                                <li>
                                    <h4>Titolare responsabile e incaricato</h4>
                                    Il titolare del trattamento dei dati, nonché responsabile e incaricato è il Sig. Achille Bonzini, nato a Milano il 16/07/1960 e, se ne hai bisogno, è reperibile presso la società, da lui amministrata, denominata Farmacontatto S.r.l. in Via Giambellino, 119 20146 Milano, via mail all’indirizzo achille.bonzini@farmacontatto.it o mediante il sito www.farmacontatto.it .</li>
                                <li>
                                    <h4>Disponibilità</h4>
                                    Questa informativa è disponibile ogni volta che ti vengono richiesti e/o raccolti dati che ti riguardano e comunque è sempre consultabile sul sito www.farmacontatto.it .</li>
                                <li>
                                    <h4>Trattamento e fine</h4>
                                    I tuoi dati raccolti saranno trattati, nel rispetto della normativa e con correttezza, saranno pertinenti e non eccedenti rispetto ai motivi e allo scopo per i quali sono stati forniti in modo da garantire adeguata sicurezza e riservatezza e per impedire l’acceso e/o l’utilizzo e/o il trattamento non autorizzato e/o illecito; ed in particolare sono raccolti per :</li>
                            </ul>
                        </li>
                        <li style={{ listStyleType: 'none' }}>
                            <ol>
                                <li style={{ listStyleType: 'none' }}>
                                    <ol>
                                        <li>obblighi di legge, regolamenti e normativa comunitaria, disposizioni d’autorità e/o di organi di vigilanza e controllo.</li>
                                        <li>motivi di adempimenti contrattuali relativamente ai servizi o prestazioni che ci hai richiesto.</li>
                                        <li>invio di comunicazioni relative a servizi da te richiesti o da noi forniti ed effettuati attraverso l’invio di materiale per via elettronica (ad esempio e-mail, sms, newsletter);</li>
                                        <li>comunicazione e/o cessione dei dati a terzi per l’espletamento degli adempimenti contrattuali (vedi punto 2) con modalità tradizionali e/o automatizzate.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ul>
                    Sei libero di revocare, in ogni momento, il consenso espresso esclusivamente in relazione ai motivi riportati nel punto 3, essendo gli altri obbligatori per legge o necessari ad adempimenti di contratti in vigore.
                    <ul>
                        <li style={{ listStyleType: 'none' }}>
                            <ul>
                                <li>
                                    <h4>Fonte</h4>
                                    I dati raccolti per le richieste e gli adempimenti contrattuali provengono direttamente da te, da terzi o da banche dati esterne.</li>
                                <li>
                                    <h4>Modalità</h4>
                                    Per gli adempimenti e i servizi da te richiesti i tuoi dati potranno essere trattati anche da altri responsabili, quali persone, aziende o società esterne al “Titolare”, che potrebbero avere compiti di gestione controllo e trattamento anche mediante loro incaricati che li potranno utilizzare seguendo le iscrizioni del Titolare con strumenti manuali e/o informatici anche a distanza e garantendone la sicurezza.</li>
                                <li>
                                    <h4>Natura e conferimento</h4>
                                    La fornitura dei tuoi dati può essere obbligatoria o no dipende dal tipo di servizio o prestazione richiesta, in particolare in relazione ai punti 1, 2 e 4 essi sono obbligatori e senza consenso al trattamento non sarà possibile al Titolare svolgere l’incarico o prestare il servizio richiesto. In relazione al punto 3 il conferimento è facoltativo e potrà essere revocato in qualsiasi momento.</li>
                                <li>
                                    <h4>Comunicazione</h4>
                                    I dati forniti non saranno diffusi ma saranno trattati solo per motivi strettamente legati al tipo di attività da te richiesta anche con modalità informatiche telematiche e potranno essere comunicati a collaboratori esterni, agenti, banche e/o finanziarie, controparti, professionisti quali avvocati notai commercialisti, consulenti, collegi, organi di vigilanza e autorità e a tutte le figure necessarie allo svolgimenti dell’attività richiesta. Tutti questi soggetti sono a loro volta titolari e pertanto a autonomi e responsabili del trattamento per quanto loro compete anche in relazione ai loro dipendenti.</li>
                                <li>
                                    <h4>Tempi e conservazione</h4>
                                    La conservazione durerà il tempo necessario a fornire il servizio e/o la prestazione richiesta e ad assolvere obblighi di legge e sono fissati in 10 anni se i dati sono forniti per le attività di cui al punto 3.</li>
                                <li>
                                    <h4>Trasferimento all’estero</h4>
                                    I dati forniti potranno essere trasferiti all’estero, all’interno dell’Unione Europea o in paesi extra UE nei limiti del regolamento, solo in relazione alle attività da te richieste e previa tua autorizzazione.</li>
                                <li>
                                    <h4>I tuoi diritti</h4>
                                    Nei limiti del regolamento (artt 15-23) hai il diritto di :</li>
                                <li style={{ listStyleType: 'none' }}>
                                    <ol>
                                        <li class="li11"><span class="s3">Accedere ai tuoi dati conservati in archivi cartacei e/o elettronici.</span></li>
                                        <li class="li11"><span class="s3">Chiederne la rettifica, l’aggiornamento, la cancellazione (massimo entro 1 mese) e/o fare opposizione al loro trattamento per uno specifico motivo.</span></li>
                                        <li class="li12"><span class="s3"><span class="s3">Ottenere la limitazione al trattamento e portabilità nei limiti del Regolamento (artt. 18-20).</span></span></li>
                                    </ol>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    In qualsiasi momento hai il diritto di revocare il consenso al trattamento dei dati e di opporti al trattamento per finalità di marketing (Diritto di Opposizione). L’esercizio del diritto dovrà essere pertinente e motivato e non potrà avere come conseguenza l’impossibilità di esecuzione di un contratto in essere. In tal senso potrai inviare richiesta al Titolare alle coordinate sopra indicate. Se lo ritieni necessario potrai inviare reclamo al Garante (maggiori info su: www.garanteprivacy.it) mediante raccomandata AR a: Garante per la protezione dei dati personali, Piazza di Monte Citorio, 121 00186 Roma, oppure inviando una e-mail all’indirizzo garante@gpdp.it o via Fax allo 06.696773785.
                    <ul>
                        <li>
                            <h4>Dati particolari</h4>
                            hai il diritto di dare specifico consenso in relazione a eventuali dati idonei a rivelare l’origine razziale e/o etnica, le convinzioni filosofiche, religiose, politiche, sindacali. i dati genetici, relativi alla salute o alla vita sessuale. questi dati potranno essere usati solo se indispensabili ad espletare le attività richieste.</li>
                    </ul>
                </div>
            </div>
            <br></br>
        </>
    )
}