import React from "react";
import { Link as Links } from 'react-scroll';


export const Testa_xs = () => {

    document.title = 'Gestione Cookies';

    return (
        <>
            <div className="row justify-content-around" style={{ backgroundColor: 'rgba(77, 100, 141, 0.6)', height: '100vh', color: 'white' }}>

                <div className="col-md-12 pt-4" >
                    <h1 className="display-2 pt-5" style={{ fontFamily: 'Roboto, Sans-serif', fontSize: '5vw', fontWeight: '600', stroke: '#000', strokeWidth: '1px', textShadow: '2px 3px 2px #222222' }}>
                        Noi compriamo farmacie</h1>
                </div>
                <div className="col-md-6 col-sm-4">
                    <div className="card flex-md-row shadow-sm" style={{ backgroundColor: "#d0e1f9", color: "#4d648d" }}>
                        <div className="card-body d-flex flex-column align-items-start">
                            <h2 className="mb-0">
                                <div className="card-text" style={{ color: "#4d648d" }}
                                    href="#">Compriamo farmacie per investitori del settore</div>
                            </h2>
                            <p className="card-text mb-3">non necessariamente farmacisti, che vogliono acquistare una farmacia per darla in gestione a farmacisti esperti.</p>
                            <div className="col-md-12 text-center">
                                <p><Links className="btn btn-secondary" style={{ backgroundColor: "#4d648d", color: "#d0e1f9" }} to="Cosa facciamo" spy={true} smooth={true} offset={-50} duration={500} role="button">Scopri di più »</Links></p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-md-4 col-sm-4 mb-5 ">
                    <div className="card flex-md-row shadow-sm" style={{ backgroundColor: "#4d648d" }}>
                        <div className="card-body d-flex flex-column align-items-start">
                            <h2 className="mb-0">
                                <div className="card-text" style={{ color: "#d0e1f9" }} href="#">Compriamo farmacie per nostri selezionati clienti</div>
                            </h2>
                            <p className="card-text mb-3" style={{ color: "#d0e1f9" }} >farmacisti, ma non solo, che vogliono comprare una farmacia e gestirla personalmente</p>
                            <div className="col-md-12 text-center">
                                <p><Links className="btn btn-secondary" style={{ backgroundColor: "#d0e1f9", color: "#4d648d" }} to="Compriamo la tua farmacia" spy={true} smooth={true} offset={-160} duration={500} role="button">Scopri di più »</Links></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}