import React from "react";
import "./css/blog.css";
import { Link as Links } from 'react-scroll';
import { Navbar1 } from './navbar1';

import { Testa_xl } from './Testa_xl';
import { Testa_lg } from './Testa_lg';
import { Testa_md } from './Testa_md';
import { Testa_sm } from './Testa_sm';
import { Testa_xs } from './Testa_xs';

import Noicompriamofarmacie from './images/noicompriamofarmacie.jpg';
import Compriamo_farmacie from './images/compriamo-farmacie.jpg';
import Chi_siamo from './images/chi-siamo.jpg';
import La_squadra from './images/la-squadra.jpg';

document.title = 'Noicompriamofarmacie';

export const Home = () => {

  return (
    <>
      <Navbar1 />

      <div className="rounded text-body-emphasis">

        <div className="container-fluid g-0" style={{

          backgroundImage: `url(${Noicompriamofarmacie})`,
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: "center",
          backgroundPositionY: "center",
          title: "Noicompriamofarmacie",
          alt: "Noicompriamofarmacie"
        }}>

          {/* Visibile su schermi extra grandi */}
          <div className="container-fluid d-none d-xl-block pt-5" id='Noicompriamofarmacie'>
            <Testa_xl />
          </div>

          {/* Visibile su schermi grandi */}
          <div className="container-fluid d-none d-lg-block d-xl-none pt-5" id='Noicompriamofarmacie'>
            <Testa_lg />
          </div>

          {/* Visibile su schermi medi */}
          <div className="container-fluid d-none d-md-block d-lg-none pt-5" id='Noicompriamofarmacie'>
            <Testa_md />
          </div>

          {/* Visibile su schermi piccoli */}
          <div className="container-fluid d-none d-sm-block d-md-none pt-5" id='Noicompriamofarmacie'>
            <Testa_sm />
          </div>

          {/* Visibile su schermi extra piccoli */}
          <div className="container-fluid d-xs-block d-sm-none pt-5" id='Noicompriamofarmacie'>
            <Testa_xs />
          </div>

        </div>
      </div>


      <div className="container-fluid pb-5" style={{ background: "linear-gradient(#f5f9fE, #ffffff)" }}>
        <div className="row pt-5">
          {/* Hidden su schermi medi e grandi */}
          <div className="col-sm-12 d-md-none pt-5 my-auto">
            <h2 className="display-5 ">Compriamo la tua farmacia</h2>
          </div>
          {/* Hidden su schermi piccoli */}
          <div className="col-md-12 d-none d-md-block my-auto">
            <h2 className="display-5 ">Compriamo la tua farmacia</h2>
          </div>
        </div>
      </div>

      {/* cosa facciamo */}

      <div className="container-fluid p-5" id='Compriamo la tua farmacia' style={{ background: "linear-gradient(#f5f9fE, #ffffff)" }}>
        <div className="row h-100 gx-5">
          <div className="col-sm-6 my-auto order-sm-12">
            <div className="card-body">
              <h2 className="card-title">Cosa facciamo</h2>
              <br></br>
              <h3 className="card-title" style={{ color: "#696969" }}>Noi compriamo farmacie su incarico di nostri selezionati clienti</h3>
              <br></br>
              <h4 className="card-text" style={{ color: "#ff8400" }}>ecco cosa facciamo..</h4>
              <ul className="list-group" style={{ textAlign: 'left', border: '0px' }}>
                <li className="list-group-item" style={{ border: '0px' }}>Compriamo un farmacia per singoli farmacisti privati</li>
                <li className="list-group-item" style={{ border: '0px' }}>Aiutiamo farmacisti che vogliono comprare una farmacia in società tra loro.</li>
                <li className="list-group-item" style={{ border: '0px' }}>Aiutiamo investitori a comprare farmacie da gestire o da far gestire.</li>
              </ul>
            </div>
            <br></br>
          </div>
          <div className="col-sm-6 my-auto order-sm-1">
            <img src={Compriamo_farmacie} className="card-img" alt="Compriamo la tua farmacia" title="Compriamo la tua farmacia"
              style={{ maxWidth: '400px', minWidth: '200px', height: 'auto', borderRadius: '100%' }} />
          </div>
        </div>
      </div>

      {/* chi siamo */}
      <div className="container-fluid p-5" id='Chi siamo' style={{ background: "linear-gradient(#f5f9fE, #ffffff)" }}>
        <div className="row h-100 gx-5">
          <div className="col-sm-6 my-auto order-sm-1">
            <div className="card-body">
              <h2 className="card-title">Chi siamo</h2>
              <br></br>
              <h3 className="card-title" style={{ color: "#696969" }}>Siamo un gruppo d'agenzie d'intermediazione</h3>
              <br></br>
              <p style={{ color: "#696969" }}>che collaborano tra loro e che si occupano esclusivamente di compravendita di farmacie su tutto i territorio nazionale.</p>
              <h4 className="card-text" style={{ color: "#ff8400" }}>Ci occupiamo esclusivamene di ..</h4>
              <ul className="list-group" style={{ textAlign: 'left' }}>
                <li className="list-group-item" style={{ border: '0px' }}>Ricerca di farmacie in vendita per conto di nostri selezionati clienti</li>
                <li className="list-group-item" style={{ border: '0px' }}>Acquisizione e vendita di farmacie in tutta Italia.</li>
                <li className="list-group-item" style={{ border: '0px' }}>Intermediazione e compravendita di farmacie.</li>
              </ul>
            </div>
            <br></br>
          </div>
          <div className="col-sm-6 my-auto order-sm-12">
            <img
              src={Chi_siamo}
              className="card-img" alt="Farmacie in vendita" title="Farmacie in vendita"
              style={{ maxWidth: '400px', minWidth: '200px', height: 'auto', borderRadius: '100%' }} />
          </div>
        </div>
      </div>

      {/* la squadra */}
      <div className="container-fluid p-5" id='Cosa facciamo' style={{ background: "linear-gradient(#f5f9fE, #ffffff)" }}>
        <div className="row h-100 gx-5">
          <div className="col-sm-6 my-auto order-sm-12">
            <div className="card-body">
              <h2 className="card-title">Come operiamo per voi</h2>
              <br></br>
              <h3 className="card-title" style={{ color: "#696969" }}>Nella massima discrezione cerchiamo farmacie in vendita in tutta Italia.</h3>
              <br></br>
              <h4 className="card-text" style={{ color: "#ff8400" }}>Operiamo su tutto il territorio nazionale:</h4>
              <ul className="list-group" style={{ textAlign: 'left', border: '0px' }}>
                <li className="list-group-item" style={{ border: '0px' }}>Collaboriamo con agenzie di sicura serietà specializzate in compravendita di farmacie che condividono i nostro "modus operandi"</li>
                <li className="list-group-item" style={{ border: '0px' }}>La riservatezza è un requisito fondamentale per tutti noi e per chi collabora con noi a qualunque livello.</li>
                <li className="list-group-item" style={{ border: '0px' }}>Siamo presenti su tutto il territorio nazionale</li>
                <li className="list-group-item" style={{ border: '0px' }}>Siamo in contatto con tutte le banche e le finanziarie normalmente operanti nel settore.</li>
              </ul>
            </div>
            <br></br>
          </div>
          <div className="col-sm-6 my-auto order-sm-1">
            <img src={La_squadra} className="card-img" alt="Compravendita farmacie" title="Compravendita farmacie"
              style={{ maxWidth: '400px', minWidth: '200px', height: 'auto', borderRadius: '100%' }} />
          </div>
        </div>
      </div>
    </>
  )
}